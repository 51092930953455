import {
  Box,
  Container,
  Grid,
  Skeleton,
  Stack,
  styled,
  SwipeableDrawer,
  TextField,
  Typography,
  IconButton,
  Button,
  CircularProgress,
  Divider,
  Card,
  CardMedia,
} from "@mui/material";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import React, { useState } from "react";
import { UserAuth } from "../context/AuthContext";
import { db } from "../firebase.config";
import CustomButton from "../Components/CustomButton";
import EventCard from "../Components/EventCard";
import Iconify from "../Components/Iconify";
import { sendPasswordResetEmail } from "firebase/auth";
import { Facebook, Google } from "@mui/icons-material";
import { Variables } from "../context/VariableContext";
import CustomTextField from "../Components/CustomTextField";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";

const Home = ({ open, count }) => {
  const { user } = UserAuth();
  const navigate = useNavigate()
  const { openLogin, setOpenLogin } = Variables();
  const [loading, setLoading] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const [countTmp, setCount] = React.useState(0);
  const createEventList = (doc) => {
    setEvents((events) => [...events, doc]); // ajouter a la fin
  };


  const getData = async () => {
    try {
      setLoading(true);
      setEvents([]);
      
      if (user) {
        const docRef0 = doc(db, 'organisateurs', user.uid);
        const docSnap = await getDoc(docRef0);

        if (docSnap.exists()) {
          const eventsList = [];
          const orgId = docSnap.data().idOrg;
          if (!orgId) {
            throw new Error("orgId not found");
          }
          let q;
          q = query(
            collection(db, 'evenement'),
            where('orgId', '==', orgId),
            where('status', '==', 1),
            orderBy('dateDebut', 'desc')
          );
          const querySnapshot = await getDocs(q);
          
          // const recetteList = [];
          querySnapshot.forEach(async (snap) => {
            const event = snap;
            eventsList.push(event);
          });

          if (sessionStorage.getItem('listEvent')) {
            const list = JSON.parse(sessionStorage.getItem('listEvent'))
  
            for (let index = 0; index < list.length; index++) {
              const element = list[index];
              console.log(element)
              const docRefEvent = doc(db, 'evenement', element.id);
              const docEvent = await getDoc(docRefEvent);
              console.log(docEvent.data())
              if (docEvent.exists()) {
                const event = docEvent;
                eventsList.push(event);
              }
            }
            
          }

          // Mettre à jour les états en une seule fois
          // console.log(recetteList)
          setEvents(eventsList);
          // setRecettes(recetteList)
        }  else if (sessionStorage.getItem('listEvent')) {
          const eventsList = [];
          const list = JSON.parse(sessionStorage.getItem('listEvent'))

          for (let index = 0; index < list.length; index++) {
            const element = list[index];
            console.log(element)
            const docRefEvent = doc(db, 'evenement', element.id);
            const docEvent = await getDoc(docRefEvent);
            console.log(docEvent.data())
            if (docEvent.exists()) {
              const event = docEvent;
              eventsList.push(event);
            }
          }
          console.log(eventsList[0])
          setEvents(eventsList);
        }
        
      }
    } catch (error) {
      console.log("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  };


  React.useEffect(() => {
    (async () => {
      try {
        getData()
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, [user]);



  const goToEvent = (index) => {
    navigate(`/scanner/${events[index].id}`)
  };

  const [openConnexion, setOpenConnexion] = React.useState(open);
  const [connexion, setConnexion] = React.useState(0);



  return (
    <Container>
      <Stack sx={{ color: 'white' }} spacing={3}>
        <Typography variant="h4">Mes evenements</Typography>

        <Stack spacing={2} justifyContent='center' paddingTop={3}>
          {
            events.length === 0 && loading && <>
              <Typography variant="body2">Vous n'êtes affilié a aucun évenement pour le moment</Typography>
              <Typography variant="body2">Veuillez contacter des organisateurs pour mettre en place des partenariat</Typography>
            </>
          }

          <CustomTextField placeholder='Rechercher un evenement' />
          {events.map((event, index) => (
            <Card sx={{ bgcolor: '#292929', color: 'white', cursor: 'pointer' }} onClick={() => goToEvent(index)}>
              <Stack direction='row' spacing={1} display="flex" alignItems="center">

                <LazyLoadImage
                  src={`${event.data().idVisuel}`}
                  alt={`${event.data().idVisuel}`}
                  className="img-lazy"
                  width={100}
                  height={100}
                  style={{ objectFit: 'cover' }}
                  effect="blur" // opacity | black-and-white
                />
                <Box>
                  <Typography variant="h6">{event?.data().nomEvent}</Typography>
                  <Typography variant="body" >
                    dans 3 jours
                  </Typography>
                  <Typography variant="body1" >
                    {event?.data().participant} participants
                  </Typography>
                </Box>
              </Stack>
            </Card>))}
          <Stack>

          </Stack>
        </Stack>
      </Stack>

    </Container>
  );
};

export default Home;

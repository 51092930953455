import {
    Box,
    Container,
    Grid,
    Skeleton,
    Stack,
    styled,
    SwipeableDrawer,
    TextField,
    Typography,
    IconButton,
    Button,
    CircularProgress,
    Divider,
} from "@mui/material";
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    serverTimestamp,
    setDoc,
    where,
} from "firebase/firestore";
import React, { useState } from "react";
import { UserAuth } from "../context/AuthContext";
import { db } from "../firebase.config";
import CustomButton from "../Components/CustomButton";
import EventCard from "../Components/EventCard";
import Iconify from "../Components/Iconify";
import { sendPasswordResetEmail } from "firebase/auth";
import { Facebook, Google } from "@mui/icons-material";
import { Variables } from "../context/VariableContext";
import CustomTextField from "../Components/CustomTextField";
import { useNavigate } from "react-router-dom";
import logoImg from "../media/mainLogo Partners.png";

const Login = ({ }) => {
    const { user, logIn, signUpSecondStep, logOut, forgotPass, handleFacebookSigin, faceSecondStep } = UserAuth();
    const { openLogin, setOpenLogin } = Variables();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                console.log(user)
                if (user) {
                    navigate('/home')
                }
            } catch (error) {
                console.log(error);
            }
            setLoading(false);
        })();
    }, [user]);

    // champs
    //connexion section
    const [emailField, setEmail] = useState("");
    const [helpText, setHelp] = useState("");
    const [emailError, setEmailError] = useState("");
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [passwordField, setPassword] = useState("");
    const [passError, setPassError] = useState(false);


    const toggleDrawerConnexion = (open) => (event) => {
        setOpenLogin(open);
    };

    // connexion function
    const seConnecter = async () => {
        setLoading(true)
        try {
            setPassError(false);
            setEmailError(false);
            console.log(emailField);
            console.log(passwordField);
            if (emailField === "" || passwordField === "") {
                if (emailField === "") {
                    setEmailError(true);
                }
                if (passwordField === "") {
                    setPassError(true);
                }
                setError(true);
                setErrorMsg("email et mot de passe requis pour la connexion");
                return;
            }
            const u = await logIn(emailField, passwordField);
            console.log(u)
            navigate('/home')
        } catch (error) {
            console.log(error);
            setError(true);
            setErrorMsg("Email ou mot de passe inconue");
        }
        setLoading(false)
    };



    return (
        <Container>
            <Stack justifyContent='center' alignItems='center' spacing={1} sx={{ color: 'white' }}>
                <img width={70} src={logoImg} alt="choptonticket_logo" />
                <Typography variant="subtitle1"> Conectez vous</Typography>
                <Stack spacing={2} sx={{ border: '1px solid white', borderRadius: '7px', padding: 2, width: { xs: `90%`, sm: 500 }, }}>
                    <Stack spacing={1}>
                        <Typography > Email</Typography>
                        <CustomTextField placeholder='salut' value={emailField}
                            onChange={(e) => setEmail(e.target.value)} fullWidth />
                    </Stack>
                    <Stack>
                        <Typography > Mot de passe</Typography>
                        <CustomTextField value={passwordField}
                            onChange={(e) => setPassword(e.target.value)} />
                    </Stack>
                    <CustomButton type='primaire' buttonText='Connexion' onClick={seConnecter} />
                </Stack>
            </Stack>

        </Container>
    );
};

export default Login;

import {
    Box,
    Container,
    Grid,
    Skeleton,
    Stack,
    styled,
    SwipeableDrawer,
    TextField,
    Typography,
    IconButton,
    Button,
    CircularProgress,
    Divider,
    CardContent,
    Card,
    Snackbar,
} from "@mui/material";
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    serverTimestamp,
    setDoc,
    updateDoc,
    where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { db } from "../../firebase.config";
import CustomButton from "../../Components/CustomButton";
import EventCard from "../../Components/EventCard";
import Iconify from "../../Components/Iconify";
import { sendPasswordResetEmail } from "firebase/auth";
import { Facebook, Google } from "@mui/icons-material";
import { Variables } from "../../context/VariableContext";
import CustomTextField from "../../Components/CustomTextField";
import Info from "./info";
import Monitoring from "./monitoring";
import { useParams } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useResponsive from "../../Components/hooks/useResponsive";
import { QrReader } from "react-qr-reader";
import { Html5QrcodeScanner } from "html5-qrcode";
import Scanner from "../scanner/scanner";
import { Sheet } from "react-modal-sheet";

const Affiliation = ({ open, count }) => {
    const { eventId } = useParams();
    const [scanResult, setScanResult] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [pause, setPause] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [scanValidate, setScanValidate] = useState(null);
    const [isOpen, setOpen] = useState(false);

    //  snack bar
  const [openSnack, setOpenSnack] = React.useState(false);
  const [msg, setMsg] = React.useState({ content: '', color: '' });
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };
  const showSnack = (m) => {
    setMsg(m)
    setOpenSnack(true);
  };


    const handleScanSuccess = (result, isValid) => {
        setPause(true)
        if (result?.isValid) {
            // update du billet 
            setScanResult(result);
            setOpen(true)
        } else {
            if (result?.returnCode === 'ALREADY_SCAN') {
                showSnack({ content: 'billet deja scanné', color: 'red' })
            } else if (result?.returnCode === 'NOT_VALID') {
                showSnack({ content: 'billet non valide', color: 'red' })
            } else if (result?.returnCode === 'NOT_FOUND') {
                showSnack({ content: 'Ce billet n\'existe pas', color: 'red' })
            }
            
           setPause(false)
        }
        
    };

    const validateScan = async (ticketNumber) => {
        let done = false
        try {
            const docRefTicket = doc(db, `evenement/${eventId}/billets`, `${ticketNumber}`);
            await updateDoc(docRefTicket, {
                scanner: 1
            });
            done = true
            showSnack({ content: 'Billet validé', color: 'green' })
            setOpen(false)
            setPause(false)
        } catch (error) {
            console.error(error)
        }
        return done
    };

    return (
        <Container sx={{ color: 'white' }}>
            <CustomTextField fullWidth placeholder='Rechercher un billet' />
            <Stack>
                <Box></Box>
            </Stack>
            <Scanner onScanSuccess={handleScanSuccess} eventId={eventId} refresh={refresh} pause={pause} /> 
            <Sheet isOpen={isOpen} onClose={() => setOpen(false)}
                snapPoints={[400]}>
                <Sheet.Container>
                    <Sheet.Header />
                    <Sheet.Content>
                        <Stack padding={2} spacing={2}>
                            <Stack direction='row' spacing={1}>
                                <Iconify icon='pepicons-pencil:ticket' sx={{height: 30, width: 30}}/>
                                <Stack spacing={1}>
                                    <Typography variant="subtitle1"> <strong>early bird</strong></Typography>
                                    <Typography>N° {scanResult?.ticketNumber}</Typography>
                                    <Typography textAlign='center'>afficher plus d'info</Typography>
                                </Stack>

                            </Stack>
                            {
                                isLoading ? <CircularProgress /> : <CustomButton buttonText={'Valider'} onClick={() => validateScan(scanResult?.ticketNumber)}/>
                            }
                            
                        </Stack>
                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop />
            </Sheet>
             
            <Snackbar
          open={openSnack}
          autoHideDuration={5000}
          onClose={handleCloseSnack}
          message={
            <Stack spacing={1} direction={'row'} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant='boody2'>{msg.content}{' '}</Typography>
              <Iconify
                sx={{ color: msg.color }}
                icon={`${msg.color === 'red' ? 'system-uicons:cross-circle' : 'mdi:success-circle-outline'}`}
              />
            </Stack>
          }
        />
        </Container>
    );
};

export default Affiliation;

import {
    Box,
    Container,
    Grid,
    Skeleton,
    Stack,
    styled,
    SwipeableDrawer,
    TextField,
    Typography,
    IconButton,
    Button,
    CircularProgress,
    Divider,
    Snackbar,
    TableContainer,
    Table,
    TableHead,
    Paper,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
} from "@mui/material";
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    serverTimestamp,
    setDoc,
    where,
} from "firebase/firestore";
import React, { useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { db } from "../../firebase.config";
import CustomButton from "../../Components/CustomButton";
import EventCard from "../../Components/EventCard";
import Iconify from "../../Components/Iconify";
import { sendPasswordResetEmail } from "firebase/auth";
import { Facebook, Google } from "@mui/icons-material";
import { Variables } from "../../context/VariableContext";
import CustomTextField from "../../Components/CustomTextField";
import Scrollbar from "../../Components/scrollBar";
import { useNavigate } from "react-router-dom";

const Historique = ({ }) => {
    const { openLogin, setOpenLogin, refresh } = Variables();
    const [loading, setLoading] = React.useState(false);
    const [events, setEvents] = React.useState([]);
    const [paiements, setPaiement] = React.useState([]);
    const [countTmp, setCount] = React.useState(0);
    const createEventList = (doc) => {
        setEvents((events) => [...events, doc]); // ajouter a la fin
    };
    const { user } = UserAuth();
    const navigate = useNavigate()

    const [requests, setRequests] = React.useState([]);

    React.useEffect(() => {
        (async () => {
            try {
                setRequests([])
                const q = query(collection(db, `partners/${user.uid}/affiliations`));
                const snapshot = await getDocs(q);
                snapshot.forEach(async (d) => {
                    const q1 = query(collection(db, `partners/${user.uid}/affiliations/${d.id}/requests`));
                    const snapshotRequest = await getDocs(q1);
                    snapshotRequest.forEach(async (d1) => {
                        setRequests((requests) => [...requests, { affId: d.data().affId, info: d1 }]);
                    })

                });
            } catch (error) {
                console.log(error);
            }
            setLoading(false);
        })();
    }, [user, refresh]);

    const handleCopy = (link) => {
        navigator.clipboard.writeText(link)
        setMsg({ content: 'Lien copié dans le papier presse', color: 'green' });
        setOpenSnack(true);
    };

    //  snack bar
    const [openSnack, setOpenSnack] = React.useState(false);
    const [msg, setMsg] = React.useState({ content: '', color: '' });
    const handleCloseSnack = () => {
        setOpenSnack(false);
    };


    return (
        <Box>

            <Scrollbar>
                <TableContainer component={Paper} sx={{ bgcolor: '#292929', color: 'white' }}>
                    <Table sx={{ minWidth: 500 }} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: "white" }} align="left">Evenement</TableCell>
                                <TableCell sx={{ color: "white" }} align="right">Montant</TableCell>
                                <TableCell sx={{ color: "white" }} align="right">Date de demande</TableCell>
                                <TableCell sx={{ color: "white" }} align="right">Date de virement</TableCell>
                                <TableCell sx={{ color: "white" }} align="right">Staut</TableCell>
                            </TableRow>
                        </TableHead>
                        {loading ? (
                            <caption>
                                <Skeleton variant="rounded" width="100%" />
                            </caption>
                        ) : requests.length === 0 && !loading ? (
                            <caption>
                                <Stack spacing={1} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                                    <Iconify icon="icomoon-free:drawer2" sx={{ width: 30, height: 30 }} />
                                    <Typography variant="caption">Aucune donnée</Typography>
                                </Stack>
                            </caption>
                        ) : (
                            <>
                                {
                                    <TableBody>
                                        {requests.length > 0 &&
                                            requests.map((request, index) => (
                                                <TableRow key={index} sx={{ color: 'white' }} hover>
                                                    <TableCell align="left" sx={{ color: "white" }}>
                                                        <Typography variant='subtitle1'>{request.info.data().event}</Typography>
                                                    </TableCell>

                                                    <TableCell align="right" sx={{ color: "white" }}>
                                                        <Typography variant='body1'>{request.info.data().montant}</Typography>
                                                    </TableCell>
                                                    <TableCell align="right" sx={{ color: "white" }}>
                                                        <Typography variant='body1'>{request.info.data().event}</Typography>
                                                    </TableCell>
                                                    <TableCell align="right" sx={{ color: "white" }}>
                                                        <Typography variant='body1'>-</Typography>
                                                    </TableCell>

                                                    <TableCell align="right">
                                                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">

                                                            {request.info.data().status === 1 ? (
                                                                <Box
                                                                    component="button"
                                                                    padding={0.5}
                                                                    sx={{
                                                                        borderRadius: '5px',
                                                                        fontSize: 10,
                                                                        backgroundColor: '#F5EFFA',
                                                                        border: '1px solid #B991DE',
                                                                    }}
                                                                >
                                                                    traité
                                                                </Box>
                                                            )
                                                                : (
                                                                    <Box
                                                                        component="button"
                                                                        padding={0.5}
                                                                        sx={{
                                                                            borderRadius: '5px',
                                                                            fontSize: 10,
                                                                            backgroundColor: '#F5EFFA',
                                                                            border: '1px solid #B991DE',
                                                                        }}
                                                                    >
                                                                        En cours de traitement
                                                                    </Box>
                                                                )}
                                                        </Stack>
                                                    </TableCell>

                                                </TableRow>
                                            ))}
                                    </TableBody>
                                }
                            </>
                        )}
                    </Table>
                </TableContainer>
                {
                    /**
                     <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                     */
                }

            </Scrollbar>

            <Snackbar
                open={openSnack}
                autoHideDuration={5000}
                onClose={handleCloseSnack}
                message={
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography paddingRight={1} variant='body2'>{msg.content}{' '}</Typography>
                        <Iconify
                            sx={{ color: msg.color }}
                            icon={`${msg.red ? 'system-uicons:cross-circle' : 'mdi:success-circle-outline'}`}
                        />
                    </Box>
                }
            />
        </Box>
    );
};

export default Historique;

import {
    Box,
    Container,
    Grid,
    Skeleton,
    Stack,
    styled,
    SwipeableDrawer,
    TextField,
    Typography,
    IconButton,
    Button,
    CircularProgress,
    Divider,
    Snackbar,
} from "@mui/material";
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    serverTimestamp,
    setDoc,
    where,
} from "firebase/firestore";
import React, { useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { db } from "../../firebase.config";
import CustomButton from "../../Components/CustomButton";
import EventCard from "../../Components/EventCard";
import Iconify from "../../Components/Iconify";
import { sendPasswordResetEmail } from "firebase/auth";
import { Facebook, Google } from "@mui/icons-material";
import { Variables } from "../../context/VariableContext";
import CustomTextField from "../../Components/CustomTextField";
import { QrReader } from "react-qr-reader";

const Info = ({ affiliation }) => {
    const [scanResult, setScanResult] = useState(null);

  const handleScan = (data) => {
    if (data) {
      setScanResult(data);
      // Appeler la fonction de vérification de ticket ici
    }
  };

  const handleError = (error) => {
    console.error(error);
  };

    return (
        <Container>
        <Typography variant="h4" align="center" gutterBottom>Scanner les Tickets</Typography>
        <Box display="flex" justifyContent="center" marginTop={4} sx>
          <QrReader
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: '100%' }}
          />
        </Box>
        {scanResult && (
          <Typography variant="h6" color="success.main" align="center">
            Ticket scanné : {scanResult}
          </Typography>
        )}
      </Container>
    );
};

export default Info;

import { Navigate, useParams, useRoutes } from "react-router-dom";
import ProtectedRoute from "./Components/protectedRoute";
// layouts

//

import Layout from "./layout/layout";
import EventPage from "./pages/eventPage";
import Home from "./pages/home";
import Profile from "./pages/profile";
import SearchPage from "./pages/searchPage";
import Confirmation from "./pages/confirmation";
import Organisateur from "./pages/organisateur";
import Categorie from "./Components/categorie";
import Article from "./Components/article";
import Cgp from "./Components/legacy/cgp";
import Cgu from "./Components/legacy/cgu";
import Login from "./pages/login";
import Affiliation from "./pages/dashboard/affiliationDetails";
import Paie from "./pages/paie";
import Authenticate from "./pages/authenticate";

// ----------------------------------------------------------------------

export default function Router() {
  const params = useParams();
  return useRoutes([
    {
      path: "/",
      element: <Layout />,

      children: [
        { path: "/", element: <Navigate to="/home" /> },
        { path: "login", element: <Login /> },
        { path: "authenticate", element: <Authenticate /> },
        {
          path: "fr/profile",
          element: (
            <ProtectedRoute>
              <Profile />,
            </ProtectedRoute> 
          ),
        },
        {
          path: "home",
          element: (
            <ProtectedRoute>
              <Home />,
            </ProtectedRoute> 
          ),
        },
        {
          path: "/scanner/:eventId",
          element: (
            <ProtectedRoute>
              <Affiliation />,
            </ProtectedRoute> 
          ),
        }
      ],
    },

  ]);
}

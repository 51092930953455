import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//

import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import { Typography } from '@mui/material';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  background:'#181818',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: 'calc(100vh - 64px)',
  paddingTop: APP_BAR_MOBILE,
  marginTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(0),
  [theme.breakpoints.up('lg')]: {
    //paddingTop: APP_BAR_DESKTOP ,
    //marginTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function Layout() {
  const [open, setOpen] = useState(false);

  return (
    <RootStyle>
      {/* <DashboardNavbar onOpenSidebar={() => setOpen(true)} />*/}
      <Navbar />
      <MainStyle>
        {/**<Typography>Salut</Typography> */}
        
        <Outlet />
        
      </MainStyle>
    </RootStyle>
  );
}
